import "./HeroImgStyles.css";
import { Link } from "react-router-dom";
import React from "react";
import image2 from "../assets/image2.jpg";

const HeroImg = () => {
  return (
    <div className="hero">
      <div className="mask">
        <img className="into-img" src={image2} alt="images" />
      </div>
      <div className="content">
        <h5>"This portfolio is developed in React.js"</h5>
        <p>HI, I'M Ghulam Mustafa.</p>
        <h1>Web Developer.</h1>

        <div>
          <Link to="/Project" className="btn">
            Projects
          </Link>
          <Link to="/Contact" className="btn btn-light">
            Contact
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeroImg;
