import "./WorkCardStyles.css";
import React from "react";
import { NavLink } from "react-router-dom";

export const WorkCard = (props) => {
  return (
    <>
      <div className="project-card">
        <img src={props.imgsrc} alt="loading" />
        <h2 className="project-title">{props.title}</h2>
        <div className="pro-details">
          <p>{props.text}</p>
          <div className="pro-btns">
            <NavLink to={props.view} className="btn">
              view
            </NavLink>
            {/* <NavLink to="https://www.google.com" className="btn">
              Source
            </NavLink> */}
          </div>
        </div>
      </div>
    </>
  );
};
