import { Link } from "react-router-dom";
import "./AboutContentStyles.css";
import React1 from "../assets/react1.jpg";
import React2 from "../assets/react2.jpg";

import React from "react";

const Aboutcontent = () => {
  return (
    <div className="about">
      <div className="left">
        <h1>Who Am I?</h1>

        <p>
          <span className="si">I </span> am an experienced web developer with an
          experience of 3+ years and have worked with various technologies and
          languages. <br />
          <span className="si">I </span> have also worked as a web developer in
          a software company.
          <br />
          <span className="si">I </span> never compromise on providing quality
          work. <br />
          <span className="si">I </span> can complete projects in a very short
          period. <br />
          <span className="si">C</span>lient Satisfaction is always my first
          priority. <br /> <br />
          <h1>My services includes:</h1>
          <br />
          Wordpress Development
          <br /> Web Development <br />
          Web Application Development
          <br />
          Complete Domain and Hosting Setup <br /> PSD to Web pages <br /> Figma
          to Web pages
          <br />
          Adobe XD to Web pages etc. Portfolio website <br />
          <br />
          <h1>Different types of websites I can develop for you</h1>
          Ecommerce website <br />
          Business website <br />
          Hotel, Tour and travel website <br />
          Restaurant Website <br />
          Agency website <br />
          Blogging website <br />
          Landing Pages etc.
          <br />
          <br />
          <h1>I will develop your website with:</h1>
          100% pixel perfection <br />
          100% responsive design
          <br />
          100% your satisfaction
          <br />
          Cross Browser Compatibility
          <br />
          Speed Optimization
          <br />
          Reusable and Maintainable code <br />
          Animations
          <br />
          Website security <br />
          Transitions
          <br />
          SEO etc.
        </p>
        <Link to="/contact">
          <button className="btn">CONTACT</button>
        </Link>
      </div>
      <div className="right">
        <div className="img-container">
          <div className="img-stack top">
            <img src={React1} className="img" alt="loading" />
          </div>
          <div className="img-stack bottom">
            <img src={React2} className="img" alt="loading" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutcontent;
