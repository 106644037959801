import "./WorkCardStyles.css";
import React from "react";

import { WorkCard } from "./WorkCard";
import WorkCardData from "./WorkCardData";
import { WorkCard2 } from "./WorkCard2";
import WorkCardData2 from "./WorkCardData2";

export const Work = () => {
  return (
    <div className="work-container">
      <h1 className="project-heading">Projects</h1>
      <h3 className="fnl" style={{ color: "white" }}>
        Some of my recent projects
      </h3>
      <div className="project-container">
        {WorkCardData2.map((value, index) => {
          return (
            <WorkCard2
              key={index}
              imgsrc={value.imgsrc}
              title={value.title}
              text={value.text}
              view={value.view}
            />
          );
        })}

        {WorkCardData.map((value, index) => {
          return (
            <WorkCard
              key={index}
              imgsrc={value.imgsrc}
              title={value.title}
              text={value.text}
              view={value.view}
            />
          );
        })}
      </div>
    </div>
  );
};
