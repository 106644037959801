import headphones from "../assets/headphones.png";
import restaurant from "../assets/restaurant.png";
import blog from "../assets/blog.png";

const ProjectCardData = [
  {
    imgsrc: headphones,
    title: "Ecommerce store",
    text: "This is an ecommerce store website of selling digital products with many functionalities related to buying and selling products and is completely responsive on all the screen devices.",
    view: "https://www.shopskies.com",
  },
  {
    imgsrc: restaurant,
    title: "Restaurant Website",
    text: "This is a restaurant website consisting of their food, services, menu, comtact, specialities and online reservation etc of tables and is completely responsive on all the screen devices.",
    view: "https://www.staging.prizedoor.com",
  },
  {
    imgsrc: blog,
    title: "Blogging Website",
    text: "This is an amazing blogging website with amazing functionalities and is completely responsive on all the screen devices.",
    view: "https://www.staging.systemechanics.com",
  },
];

export default ProjectCardData;
