import image5 from "../assets/project2.png";
import image6 from "../assets/project3.png";
import project1 from "../assets/project1.png";
import portfolio from "../assets/portfolio.png";

const ProjectCardData = [
  {
    imgsrc: project1,
    title: "JobFinder Website ",
    text: "This is a website for jobseekers to help them getting hired and is completely responsive on all the screen devices.",
    view: "https://www.prizedoor.com/",
  },
  {
    imgsrc: image5,
    title: "Ecommerce Website",
    text: "This is a basic ecommerce website consisting of multiple types of projects and is completely responsive on all the screen devices.",
    view: "https://www.shoptalkies.com/",
  },
  {
    imgsrc: image6,
    title: "Consulting Website",
    text: "This is a consulting website of a software company who provides solutions to the problems related to software and is completely responsive on all the screen devices.",
    view: "https://www.systemechanics.com",
  },
  {
    imgsrc: portfolio,
    title: "Portfolio Website",
    text: "This is a portfolio website which you are currently seeing and I have developed this in react js. This is completely responsive on all the screen devices.",
    view: "https://www.buyonify.com",
  },
];

export default ProjectCardData;
